.todeliver_card {
    /* width: 300px; */
    border: 1px solid #000D29;
    /* background-color: #DCDCDC !important; */
    background-color: #c4b148 !important;
    border-radius: 8px;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
    padding: 12px;
    margin-top: 0px;
    margin-bottom: 15px;
    text-align: left;
    max-width: 900px;
    max-width: 900px;

    border-bottom-left-radius:8px !important;
    border-bottom-right-radius: 8px  !important;
  }

  .outfordelivery_card {
    /* width: 300px; */
    border: 1px solid #000D29;
    /* background-color: #DCDCDC !important; */
    background-color: #52a852 !important;
    border-radius: 8px;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
    padding: 12px;
    margin-top: 0px;
    margin-bottom: 15px;
    text-align: left;
    max-width: 900px;
    max-width: 900px;

    border-bottom-left-radius:8px !important;
    border-bottom-right-radius: 8px  !important;
  }

  .reschedule_card {
    /* width: 300px; */
    border: 1px solid #000D29;
    /* background-color: #DCDCDC !important; */
    background-color: #dacc82 !important;
    border-radius: 8px;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
    padding: 12px;
    margin-top: 0px;
    margin-bottom: 15px;
    text-align: left;
    max-width: 900px;
    max-width: 900px;

    border-bottom-left-radius:8px !important;
    border-bottom-right-radius: 8px  !important;
  }


  .delivered_card {
    /* width: 300px; */
    border: 1px solid #000D29;
    /* background-color: #DCDCDC !important; */
    background-color: #3e8499 !important;
    border-radius: 8px;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
    padding: 12px;
    margin-top: 0px;
    margin-bottom: 15px;
    text-align: left;
    max-width: 900px;
    max-width: 900px;

    border-bottom-left-radius:8px !important;
    border-bottom-right-radius: 8px  !important;
  }

  .orderplaced_card {
    /* width: 300px; */
    border: 1px solid #000D29;
    /* background-color: #DCDCDC !important; */
    background-color: #e6e93f !important;
    border-radius: 8px;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
    padding: 12px;
    margin-top: 0px;
    margin-bottom: 15px;
    text-align: left;
    max-width: 900px;
    max-width: 900px;

    border-bottom-left-radius:8px !important;
    border-bottom-right-radius: 8px  !important;
  }

  .ingredients_card {
    /* width: 300px; */
    border: 1px solid #000D29;
    /* background-color: #DCDCDC !important; */
    background-color: #df87e2 !important;
    border-radius: 8px;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
    padding: 12px;
    margin-top: 0px;
    margin-bottom: 15px;
    text-align: left;
    max-width: 900px;
    max-width: 900px;

    border-bottom-left-radius:8px !important;
    border-bottom-right-radius: 8px  !important;
  }

  .cooking_card {
    /* width: 300px; */
    border: 1px solid #000D29;
    /* background-color: #DCDCDC !important; */
    background-color: #f07f7f !important;
    border-radius: 8px;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
    padding: 12px;
    margin-top: 0px;
    margin-bottom: 15px;
    text-align: left;
    max-width: 900px;
    max-width: 900px;

    border-bottom-left-radius:8px !important;
    border-bottom-right-radius: 8px  !important;
  }

  .cooling_card {
    /* width: 300px; */
    border: 1px solid #000D29;
    /* background-color: #DCDCDC !important; */
    background-color: #5cdee2 !important;
    border-radius: 8px;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
    padding: 12px;
    margin-top: 0px;
    margin-bottom: 15px;
    text-align: left;
    max-width: 900px;
    max-width: 900px;

    border-bottom-left-radius:8px !important;
    border-bottom-right-radius: 8px  !important;
  }

  .noorderyet_card {
    /* width: 300px; */
    border: 1px solid #000D29;
    background-color: #000D29 !important;
    border-radius: 8px;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.1);
    padding: 12px;
    margin-top: 20px;
    text-align: center;
    max-width: 900px;
}
  
  .blurred-background {
    filter: blur(5px);
  }

  .button-container {
    display: flex;
    justify-content: center;
  }


  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .button-row {
    width: 100%; /* Adjust the width as needed */
    display: flex;
    justify-content: center;
    margin-bottom: 10px; /* Space between buttons */
  }
  
  .button-row button {
    width: auto; /* Adjust button width if needed */
  }