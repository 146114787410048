/* DatePicker.css */
.date-picker {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: calc(100% - 20px); /* Ensures 10px padding on each side */
  box-sizing: border-box;
}

.date-picker input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box;
}

.calendar {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  box-sizing: border-box;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
}

.calendar-header button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding: 5px;
  box-sizing: border-box;
}

.calendar-day-name,
.calendar-day {
  text-align: center;
  padding: 8.5px;
  box-sizing: border-box;
}

.calendar-day {
  cursor: pointer;
}

.calendar-day.empty {
  cursor: default;
  background-color: #f7f7f7;
}

.calendar-day.selected {
  background-color: #007bff;
  color: white;
  border-radius: 4px;
}
